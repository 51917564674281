import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';

import { propTypes } from '../../../util/types';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
} from '../../../util/reactIntl';
import {
  AspectRatioWrapper,
  Button,
  IconClose,
  IconArrowHead,
  ResponsiveImage,
} from '../../../components';

// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery-visible.css';

import css from './ListingImageGalleryVisible.module.css';

const ListingImageGalleryVisible = props => {
  const {
    intl,
    rootClassName = null,
    className = null,
    images,
    imageVariants,
    thumbnailVariants = null,
    primaryImageId,
  } = props;

  const thumbVariants = thumbnailVariants || imageVariants;
  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      image: img,
    };
  });

  const [chosenImage, setChosenImage] = useState(
    primaryImageId
      ? items[items.findIndex(item => item.image.id.uuid == primaryImageId)]
      : items[0],
    [images]
  );
  useEffect(() => {
  
    setChosenImage(
      primaryImageId
        ? items[items.findIndex(item => item.image.id.uuid == primaryImageId)]
        : items[0]
    );
  }, [props.images]);

  const renderItem = item => {
    return (
      <AspectRatioWrapper width={1} height={1} className={css.itemWrapper}>
        <div className={css.itemCentering}>
          <ResponsiveImage
            rootClassName={css.item}
            image={item.image}
            alt={item.alt}
            variants={imageVariants}
          />
        </div>
      </AspectRatioWrapper>
    );
  };
  const renderThumbInner = item => {
    return (
      <div
        key={item.image.id.uuid}
        onClick={() => {
          setChosenImage(item);
        }}
        className={
          chosenImage?.image?.id?.uuid == item.image.id.uuid
            ? css.selectedThumbnail
            : css.unselectedThumbnail
        }
      >
        <ResponsiveImage
          rootClassName={css.thumb}
          image={item.image}
          alt={item.thumbAlt}
          variants={thumbVariants}
        />
      </div>
    );
  };

  if (items.length === 0) {
    const classes = classNames(rootClassName || css.noImage, className);
    return (
      <ResponsiveImage
        className={css.noImage}
        image={null}
        variants={[]}
        alt=""
      />
    );
  }

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div>
      {chosenImage && renderItem(chosenImage)}

      <div className={css.thumbnailsContainer}>
        {items.map(item => {
          return renderThumbInner(item);
        })}
      </div>
    </div>
  );
};


const { string, arrayOf } = PropTypes;

ListingImageGalleryVisible.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  imageVariants: arrayOf(string).isRequired,
  thumbnailVariants: arrayOf(string),

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGalleryVisible);
